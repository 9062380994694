body {
  margin: 0;
  font-family: "Roboto", sans-serif; }

header {
  color: white; }
  header small {
    display: block;
    font-size: 0.8rem; }
  header svg {
    position: absolute;
    top: 8px;
    right: 8px;
    color: white; }

h1,
h2 {
  margin: 0;
  padding: 0.5rem; }

.game {
  display: block;
  margin: auto;
  width: 95%;
  max-width: 635px; }

.boardgame {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  padding: 5px; }

.highscores {
  overflow-y: scroll;
  height: 3.3rem; }
  .highscores-container {
    font-family: monospace; }

.card {
  cursor: pointer;
  width: 14vw;
  max-width: 100px;
  height: 14vw;
  max-height: 100px;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d; }
  .card.face-up {
    transform: rotateY(180deg);
    cursor: not-allowed; }

.face {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); }
  .face--front {
    font-size: 3rem;
    text-align: center;
    line-height: 2;
    color: white; }
  .face--back {
    background-image: url(cards.png);
    background-size: 100%;
    transform: rotateY(180deg); }

[data-number="0"] {
  background-position: 0 0%; }

[data-number="1"] {
  background-position: 0 5.88235%; }

[data-number="2"] {
  background-position: 0 11.76471%; }

[data-number="3"] {
  background-position: 0 17.64706%; }

[data-number="4"] {
  background-position: 0 23.52941%; }

[data-number="5"] {
  background-position: 0 29.41176%; }

[data-number="6"] {
  background-position: 0 35.29412%; }

[data-number="7"] {
  background-position: 0 41.17647%; }

[data-number="8"] {
  background-position: 0 47.05882%; }

[data-number="9"] {
  background-position: 0 52.94118%; }

[data-number="10"] {
  background-position: 0 58.82353%; }

[data-number="11"] {
  background-position: 0 64.70588%; }

[data-number="12"] {
  background-position: 0 70.58824%; }

[data-number="13"] {
  background-position: 0 76.47059%; }

[data-number="14"] {
  background-position: 0 82.35294%; }

[data-number="15"] {
  background-position: 0 88.23529%; }

[data-number="16"] {
  background-position: 0 94.11765%; }

[data-number="17"] {
  background-position: 0 100%; }

:root {
  --remaining: 100; }

.progress {
  background-color: #c7c7c7;
  height: 3rem;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  line-height: 3rem; }
  .progress::after {
    counter-reset: variable var(--remaining);
    content: counter(variable);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center; }
  .progress-bar {
    background-size: 23em 0.25em;
    height: 100%;
    position: relative;
    transition: width 1s;
    width: calc((var(--remaining) / var(--allocated)) * 100%); }

.credits {
  text-align: center;
  font-size: small; }

header, .face--front, .progress-bar {
  background: #bc4e9c;
  background: linear-gradient(to right, #f80759, #bc4e9c);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
